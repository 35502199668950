import { h, resolveComponent } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/index'
import auth from '@/middleware/auth'
import guest from '@/middleware/guest'
import middlewarePipeline from '@/router/middlewarePipeline'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    redirect: '/login',
    meta: { middleware: [guest] },
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import('@/views/pages/Login'),
      },
    ],
  },

  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    meta: { middleware: [auth] },
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@/views/Dashboard.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  const middleware = to.meta.middleware
  const context = { to, from, next, store }
  if (!middleware) {
    return next()
  }

  middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  })
})

export default router
