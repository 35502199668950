//import router from '@/router'
import { getError } from '@/helpers/Helpers'
import AuthService from '@/services/AuthService'

export const namespaced = true

export const state = {
  user: null,
  loading: false,
  error: null,
  token: null,
}

export const mutations = {
  SET_USER(state, user) {
    state.user = user
  },
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  SET_ERROR(state, error) {
    state.error = error
  },
  SET_TOKEN(state, token) {
    state.token = token
  },
}

export const actions = {
  async login({ commit }, payload) {
    await AuthService.login(payload)
      .then((res) => {
        commit('SET_TOKEN', res.data.token)
        commit('SET_USER', res.data.user)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  logout({ commit }) {
    return AuthService.logout()
      .then(() => {
        commit('SET_USER', null)
        commit('SET_TOKEN', null)
        // dispatch('setGuest', { value: 'isGuest' })
        /*if (router.currentRoute.name !== 'login')
          router.push({ path: '/login' })*/
      })
      .catch((error) => {
        console.log(error)
        //commit('SET_ERROR', getError(error))
      })
  },
  async getAuthUser({ commit }) {
    commit('SET_LOADING', true)
    try {
      const response = await AuthService.getAuthUser()
      console.log(response)
      /*commit('SET_USER', response.data.data)
      commit('SET_LOADING', false)*/
      return response.data.data
    } catch (error) {
      commit('SET_LOADING', false)
      commit('SET_USER', null)
      commit('SET_ERROR', getError(error))
    }
  },
  setGuest(context, { value }) {
    window.localStorage.setItem('guest', value)
  },
}

export const getters = {
  authUser: (state) => {
    return state.user
  },
  token: (state) => {
    return state.token
  },
  isAdmin: (state) => {
    return state.user ? state.user.isAdmin : false
  },
  error: (state) => {
    return state.error
  },
  loading: (state) => {
    return state.loading
  },
  isLoggedIn: (state) => {
    return !!state.user && !!state.token
  },
  guest: () => {
    const storageItem = window.localStorage.getItem('guest')
    if (!storageItem) return false
    if (storageItem === 'isGuest') return true
    if (storageItem === 'isNotGuest') return false
  },
}
