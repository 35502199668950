export const namespaced = true
export const state = {
  sidebarVisible: '',
  sidebarUnfoldable: false,
}

export const mutations = {
  toggleSidebar(state) {
    state.sidebarVisible = !state.sidebarVisible
  },
  toggleUnfoldable(state) {
    state.sidebarUnfoldable = !state.sidebarUnfoldable
  },
  updateSidebarVisible(state, payload) {
    state.sidebarVisible = payload.value
  },
}
