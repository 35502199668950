export default function auth({ to, next, store }) {
  const loginQuery = { path: '/login', query: { redirect: to.fullPath } }
  const isLoggedIn = store.getters['auth/isLoggedIn']
  if (isLoggedIn) {
    next()
  } else {
    next(loginQuery)
  }
  /*if (!store.getters['auth/token']) {
    store.dispatch('auth/getAuthUser').then(() => {
      if (!store.getters['auth/authUser']) next(loginQuery)
      else next()
    })
  } else {
    next()
  }*/
}
