import axios from 'axios'
import store from '@/store'

//const sanctumUrl = process.env.VUE_APP_SERVER_URL + 'sanctum/csrf-cookie'
export const httpClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true, // required to handle the CSRF token
})

/**
 * Request interceptors, Add stored token in headers if user authenticated and it is url api
 */
httpClient.interceptors.request.use((req) => {
  const isLoggedIn = store.getters['auth/isLoggedIn']
  const isApiUrl = req.url.startsWith(process.env.VUE_APP_API_URL)
  if (isLoggedIn && isApiUrl) {
    req.headers.common.Authorization = `Bearer ${store.getters['auth/token']}`
  }
  return req
})

/*
 * Add a response interceptor
 */
httpClient.interceptors.response.use(
  (response) => {
    return response
  },
  function (error) {
    /*if (
      error.response &&
      [401, 419].includes(error.response.status) &&
      store.getters['auth/authUser'] &&
      !store.getters['auth/guest']
    ) {
      store.dispatch('auth/logout')
    }*/
    return Promise.reject(error)
  },
)
