import { httpClient } from '@/helpers/HttpClient'

const BASE_URL = process.env.VUE_APP_API_URL
const sanctum_url = process.env.VUE_APP_SERVER_URL + '/sanctum/csrf-cookie'

export default {
  async login(payload) {
    await httpClient.get(sanctum_url)
    return httpClient.post(BASE_URL + '/login', payload)
  },
  async logout() {
    await httpClient.get(sanctum_url)
    return httpClient.post(BASE_URL + '/logout')
  },
  async forgotPassword(payload) {
    await httpClient.get('/sanctum/csrf-cookie')
    return httpClient.post('/forgot-password', payload)
  },
  getAuthUser() {
    return httpClient.get('/me')
  },
  async resetPassword(payload) {
    await httpClient.get('/sanctum/csrf-cookie')
    return httpClient.post('/reset-password', payload)
  },
  updatePassword(payload) {
    return httpClient.put('/user/password', payload)
  },
  async registerUser(payload) {
    await httpClient.get('/sanctum/csrf-cookie')
    return httpClient.post('/register', payload)
  },
  sendVerification(payload) {
    return httpClient.post('/email/verification-notification', payload)
  },
  updateUser(payload) {
    return httpClient.put('/user/profile-information', payload)
  },
}
