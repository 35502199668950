import { createStore } from 'vuex'
import * as template from '@/store/modules/Template'
import * as auth from '@/store/modules/Auth'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  modules: {
    template,
    auth,
  },
  plugins: [createPersistedState()],
})
